<template>
  <div
    id="user-binding"
  >
    <b-card
      v-if="!checkOTPState"
      class="mb-0"
    >
      <h2 class="text-primary text-center">
        <strong>{{ t('title.Create') }}
          <span class="text-success">{{ $i18n.locale === 'th' ? t('title.Account') : t('title.Account') }}</span>
        </strong>
      </h2>
      <b-card-text class="mb-2 text-center">
        <h5>{{ t('title.New Account fill') }}</h5>
        <!-- already have an account? Sign In -->
      </b-card-text>
      <!-- <b-row>
        <b-col md="12">
          <b-alert
            variant="success"
            show
          >
            <div class="alert-body">
              <span>LINE NAME: </span>
            </div>
          </b-alert>
        </b-col>
        <b-col md="6">
          <b-col md="12">
            <h6>
              <strong>{{ t('title.More Information') }}</strong>
            </h6>
          </b-col>
        </b-col>
      </b-row>
      <hr> -->
      <validation-observer
        ref="newAccountForm"
      >
        <b-form>
          <div
            id="profile-phone"
          >
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <label>{{ t('patient.telephone number') }}  <span class="text-danger">*</span> </label>
                  <validation-provider
                    #default="{ errors }"
                    name="Phone"
                    rules="required|digits:10|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      v-model="ptPhone"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      autocomplete="OFF"
                      :placeholder="t('patient.telephone number')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">{{ t('account.password') }} <span class="text-danger">*</span></label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                    vid="password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="login-password"
                        :placeholder="t('account.password')"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility('P')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">{{ t('account.confirm password') }} <span class="text-danger">*</span></label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm password"
                    rules="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :type="confirmPasswordFieldType"
                        class="form-control-merge"
                        name="login-confirm-password"
                        :placeholder="t('account.confirm password')"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="confirmPasswordToggleIcon"
                          @click="togglePasswordVisibility('CP')"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <label>{{ t('title.mobile login info') }}</label>
                <b-button
                  block
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  {{ t('Button Save') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card v-else>
      <validation-observer
        ref="otpForm"
      >
        <h2 class="text-primary text-center">
          <strong class="text-success">OTP</strong>
        </h2>
        <h5 class="text-center">
          {{ t('title.OTP fill') }}
        </h5>
        <h2 class="mt-2 mb-2 text-center">
          <strong>
            ❝ {{ ptPhone.substring(0, 3) }}-{{ ptPhone.substring(3, 6) }}-{{ ptPhone.substring(6) }} ❞
          </strong>
        </h2>
        <b-card-text class="text-center">

          <v-otp-input
            class="flex justify-content-center"
            input-classes="otp-input"
            :num-inputs="6"
            separator=""
            :should-auto-focus="true"
            @on-complete="handleOnComplete"
            @on-change="handleOnChange"
          />

          <h6 class="mt-3">
            {{ t('title.OTP question') }}
          </h6>
          <h6 v-if="countDown>0">
            {{ countDown }}
            <b-spinner
              small
              variant="primary"
            />
          </h6>
          <b-button
            variant="link"
            :disabled="countDown>0"
            @click="sendOTP"
          >
            {{ t('title.OTP resend') }}
          </b-button>
          <hr>
          <b-button
            variant="primary"
            block
            type="submit"
            :disabled="validationState"
            @click.prevent="validationOTP"
          >
            <b-spinner
              v-if="validationState"
              small
            />
            {{ t('Button Save') }}
          </b-button>
        </b-card-text>
      </validation-observer>
    </b-card>
  </div>

</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
// import useJwt from '@/auth/jwt/useJwt'
import OtpInput from '@bachdgvn/vue-otp-input'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    'v-otp-input': OtpInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      idCardValue: '',
      required,
      password: '',
      confirmPassword: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      checkProfile: false,
      customerID: 0,
      patientData: [],
      ptPhone: '',
      checkOTPState: false,
      token: '',
      countDown: 60,
      validationState: false,
      OPTValue: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    // this.emailValue = this.userData.email
    this.countDownTimer()
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    togglePasswordVisibility(typeInput) {
      switch (typeInput) {
        case 'P':
          this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
          break
        case 'CP':
          this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
          break
        default: break
      }
    },
    validationForm() {
      this.$refs.newAccountForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          // this.newAccount()
          this.checkDataAccount()
        }
      })
    },
    checkDataAccount() {
      // Check User Login
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/Login',
        method: 'POST',
        data: {
          UserID: '',
          Username: this.ptPhone,
          HN: '',
          Password: this.password,
          TypeLogin: 'NM',
        },
      }).then(({ data }) => {
        // console.log(data)
        if (data.accountID !== 0 && data.customerID !== 0) {
          console.log('Get Account Data and Redirect to home page')
          this.$swal('Phone already exists')
        } else {
          console.log('New Account')
          this.checkOTPState = true
          // Sending OTP
          this.$http({
            url: `http://phrapi.sriphat.com/api/UserAuthen/OTPSending/${this.ptPhone}`,
            method: 'GET',
            data: { },
          }).then(({ data }) => {
            console.log('Sending OTP', data)
          })
        }
      }).catch(error => {
        console.log('checkDataAccount-CheckLogin', error)
      })
    },
    sendOTP() {
      // console.log('Send OTP')
      this.$http({
        url: `http://phrapi.sriphat.com/api/UserAuthen/OTPSending/${this.ptPhone}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        console.log(data)
        this.countDown = 60
        this.countDownTimer()
      })
    },
    validationOTP() {
      // console.log(this.OTPValue.length)
      localStorage.removeItem('accountLogin')
      if (this.OTPValue.length === 6) {
        this.validationState = true
        this.$http({
          url: 'http://phrapi.sriphat.com/api/UserAuthen/OTPChecker',
          method: 'POST',
          data: {
            PhoneNumber: this.ptPhone,
            OTPCode: this.OTPValue,
          },
        }).then(({ data }) => {
          // console.log(data)
          if (data) {
            localStorage.setItem('accountLogin', JSON.stringify(
              {
                phone: this.ptPhone,
                psw: this.password,
              },
            ))
            // localStorage.setItem('dataInfo', JSON.stringify({
            //   id: Math.floor(Math.random() * 10000),
            //   data: `${Math.floor(Math.random() * 10000)}${this.ptPhone}${Math.floor(Math.random() * 10000)}`,
            //   date: new Date(),
            // }))
            this.$router.replace('/profileBinding')
          } else {
            this.$swal('Error')
          }
        })
      } else {
        console.log('Show Alert Fill OTP')
      }
      this.validationState = false
    },
    handleOnComplete(value) {
      // console.log('OTP: ', value)
      this.OTPValue = value
    },
    handleOnChange(value) {
      this.OTPValue = value
      // console.log('Check OTP: ', value)
    },
  },
}
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.error {
  border: 1px solid red !important;
}
</style>
